import React, { useEffect, useState } from "react";
import MobileHomePage from "pages/homePage/MobileHomePage";
import DesktopHomePage from "pages/homePage/DesktopHomePage";
import useMediaQuery from "hooks/useMediaQuery";
import { getAllActivities } from "config/activityServices";

const HomePage = () => {
  const [Activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        setLoading(true);
        const activities = await getAllActivities();
        if (activities.status === 200) {
          setActivities(activities?.data?.data);
        } else {
          setActivities([]);
        }
      } catch (e) {
      } finally {
        setLoading(false);
      }
    };

    fetchActivities();
  }, []);

  const isMobile = useMediaQuery("(max-width: 640px)");

  return (
    <div className="w-full h-full">
      {isMobile ? (
        <MobileHomePage Activities={Activities} loading={loading} />
      ) : (
        <DesktopHomePage Activities={Activities} loading={loading} />
      )}
    </div>
  );
};

export default HomePage;
