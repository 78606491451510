import React from 'react'

const CustomSelect = ({options, handleFilter}) => {
  return (
    <select className="rounded-lg px-2 py-1" onChange={handleFilter}>
    {options.map((option, index) => (
    <option key={index} value={option?.value}>{option?.label}</option>       
    ))}
  </select>
  )
}

export default CustomSelect