import React from "react";

const CustomSearch = ({handleSearch}) => {
  return (
    <input
      type="text"
      className="rounded-lg px-2 py-1"
      placeholder="Search..."
      onChange={handleSearch}
    ></input>
  );
};

export default CustomSearch;
