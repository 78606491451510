import { truncateString } from "helpers/Helpers";
import { useContext, useEffect, useState } from "react";
import UserDefaultLogo from "../../assets/propics/defaultLogo.jpg";
import { QRCodeModalContext } from "context/QRModalContext";

const CustomCard = ({
  isEvent,
  cardTitle,
  cardDescription,
  imageName,
  userName,
}) => {
  const [textColor, setTextColor] = useState("");
  const [bgColor, setBgColor] = useState("");
  const { openQrCodeModal } = useContext(QRCodeModalContext);

  useEffect(() => {
    if (isEvent) {
      setTextColor("text-purple");
      setBgColor("bg-purple");
    } else {
      setTextColor("text-orange");
      setBgColor("bg-orange");
    }
  }, [isEvent]);

  return (
    <div className="bg-white rounded-lg shadow-lg px-4 h-[300px] w-auto overflow-hidden">
      {/* Header Section */}
      <div className="flex py-2 bg-white border-b border-lightGray">
        <span className={`text-sm font-bold ${textColor}`}>
          {isEvent ? "ÉVÉNEMENT" : "OFFRE"}
        </span>
      </div>

      {/* Title and Subtitle */}
      <div className=" py-2">
        <h2 className={`text-lg font-bold ${textColor}`}>
          {cardTitle ? truncateString(cardTitle, 15) : "Part Of"}
        </h2>
        <p className="text-sm text-gray-700">
          {truncateString(cardDescription, 24)}
        </p>
      </div>

      {/* Image Section */}
      <div className="">
        <img
          src={
            imageName
              ? `${imageName}`
              : "https://via.placeholder.com/150/cccccc/000000?text=No+Image"
          }
          alt="userImage"
          className="w-48 h-32 object-fit rounded-lg"
        />
      </div>

      {/* Footer Buttons */}
      <div className="flex justify-between items-center  py-2">
        {/* Profile Icon */}
        <div className="flex flex-row gap-1 items-center">
          <button className="w-10 h-10 flex items-center justify-center rounded-full border border-gray-300">
            <img
              src={UserDefaultLogo}
              alt="User"
              className="w-8 h-8 rounded-full"
            />
          </button>
          <p className={`${textColor}`}>{truncateString(userName, 8)}</p>
        </div>
        {/* Action Button */}
        <button
          onClick={() => openQrCodeModal()}
          className={`w-10 h-10 flex items-center justify-center rounded-full ${bgColor} text-white`}
        >
          <span className="text-lg">👆</span>
        </button>
      </div>
    </div>
  );
};

export default CustomCard;
