import { createContext, useState } from "react";

const QRCodeModalContext = createContext();

const QRCodeModalContextProvider = ({children}) => {
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);

  const openQrCodeModal = () => {
    setShowQrCodeModal(true);
  };

  const closeQrCodeModal = () => {
    setShowQrCodeModal(false);
  };

  return (
    <QRCodeModalContext.Provider
      value={{
        showQrCodeModal,
        setShowQrCodeModal,
        openQrCodeModal,
        closeQrCodeModal,
      }}
    >
    {children}
    </QRCodeModalContext.Provider>
  );
};

export { QRCodeModalContext, QRCodeModalContextProvider };
