import { errorCatch } from "./errorCatch.js";
import { PublicApiCall } from "./interceptors.js";

async function getAllActivities() {
  return await PublicApiCall()
    .get("/web/activity")
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

async function getActivityById(id) {
  return await PublicApiCall()
    .get(`/web/activity/${id}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

export { getAllActivities, getActivityById };
