import React from 'react';

function OffresIcon(props) {
  return (
    <svg
      width={23}
      height={24}
      viewBox="0 0 23 24"
      fill="none"
      {...props}
    >
      <path
        d="M1.91.524C.852.524 0 1.377 0 2.435v12.74c0 1.06.852 1.912 1.91 1.912h2.864l-.646 6.37 6.375-6.37h9.866c1.058 0 1.91-.853 1.91-1.912V2.435a1.906 1.906 0 00-1.91-1.91H1.909zm2.864 6.689a1.592 1.592 0 11-.001 3.184 1.592 1.592 0 01.001-3.184zm6.375 0a1.592 1.592 0 110 3.184 1.592 1.592 0 010-3.184zm6.356 0a1.592 1.592 0 11-.001 3.184 1.592 1.592 0 010-3.184z"
        fill={props.fill ?? '#434DE0'}
      />
    </svg>
  );
}

export default OffresIcon;
