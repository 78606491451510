import React from 'react';

function EvenementsIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M19.959 4.242a.795.795 0 00-.249-.363.814.814 0 00-.405-.178l-3.143-.453-1.398-2.8a.8.8 0 00-.298-.327.817.817 0 00-1.156.328l-1.397 2.799L8.77 3.7a.814.814 0 00-.403.178.795.795 0 00-.266.795c.035.143.11.275.217.379l2.278 2.186-.541 3.078a.783.783 0 00.323.795.816.816 0 00.848.063l2.811-1.479 2.812 1.456c.114.06.242.09.371.087a.82.82 0 00.623-.276.79.79 0 00.185-.646l-.541-3.078 2.278-2.186a.791.791 0 00.194-.811zm-3.91 2.147a.795.795 0 00-.234.707l.33 1.9-1.728-.898a.818.818 0 00-.76 0l-1.728.899.331-1.9a.783.783 0 00-.234-.708L10.62 5.045l1.939-.279a.815.815 0 00.614-.437l.864-1.733.865 1.733a.799.799 0 00.614.437l1.939.279-1.406 1.344zm-4.823 6.059l-3.143-.454-1.397-2.799a.817.817 0 00-1.454 0l-1.398 2.8-3.142.453a.814.814 0 00-.404.178.794.794 0 00-.266.795c.035.143.11.274.217.379l2.278 2.186-.54 3.078a.783.783 0 00.322.795.816.816 0 00.808.063l2.852-1.479 2.811 1.455c.114.061.242.091.372.088a.82.82 0 00.622-.276.792.792 0 00.186-.646l-.542-3.078 2.279-2.187a.792.792 0 00.198-.813.794.794 0 00-.248-.36.814.814 0 00-.403-.178h-.008zm-3.231 2.687a.794.794 0 00-.235.708l.332 1.9-1.73-.898a.819.819 0 00-.759 0l-1.753.899.332-1.9a.783.783 0 00-.235-.709l-1.405-1.343 1.938-.279a.816.816 0 00.614-.437l.865-1.734.864 1.734a.798.798 0 00.614.437l1.94.279-1.382 1.343z"
        fill={props.fill || "#fff"}
      />
    </svg>
  );
}

export default EvenementsIcon;
