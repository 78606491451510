import { createLead } from "config/leadServices";
import { PhoneModalContext } from "context/PhoneModalContext";
import { useContext, useEffect, useRef, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";

const MobileModal = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const { showMobileModal, modalData, closePhoneModal } =
    useContext(PhoneModalContext);

  const modalRef = useRef(null);

  useEffect(() => {
    setPhoneNumber("");
    setError("");
  }, [showMobileModal]);

  const validateAndSubmit = async () => {
    if (!phoneNumber) {
      setError("Phone number is required.");
      return;
    }

    if (!modalData?.cardId) {
      setError("Please select a card");
      return;
    }

    if (!isValidPhoneNumber(phoneNumber)) {
      setError("Please enter a valid phone number.");
      return;
    }

    const data = {
      phone: phoneNumber,
      activityId: modalData?.cardId,
    };

    await createLead(data);

    // Show success toast
    toast.success(
      `Phone number submitted. A message has been sent to your number. Please check it out!`,
      { position: 'top-center' }
    );

    closePhoneModal();
  };

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closePhoneModal();
    }
  };

  return (
    <>
      <ToastContainer />
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 rounded-lg p-2 flex justify-center items-center z-50 ${
          showMobileModal ? "block" : "hidden"
        }`}
        onClick={handleOutsideClick}
      >
        <div
          className="bg-white max-w-md rounded-lg p-6 shadow-lg"
          ref={modalRef}
        >
          <div className="mb-4 text-lg">
            <label
              className={`block font-medium mb-2 text-${modalData.color}`}
              htmlFor="phone-input"
            >
              Entrez votre numéro de téléphone:
            </label>
            <PhoneInput
              id="phone-input"
              defaultCountry="CH"
              value={phoneNumber}
              onChange={(value) => {
                setPhoneNumber(value);
                setError("");
              }}
              className="w-full p-3 border rounded-lg text-lg"
            />
            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
          </div>

          <button
            className={`w-full bg-${modalData.color} text-white border py-2 rounded-lg`}
            onClick={validateAndSubmit}
          >
            Soumettre
          </button>
        </div>
      </div>
    </>
  );
};

export default MobileModal;
