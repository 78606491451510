import Navbar from "components/navbar/Navbar";
import { Outlet } from "react-router-dom";


const DesktopLayout = () => {
  return (
    <div className="h-screen flex flex-col lg:px-20 xl:px-40 px-4 bg-superlightGray">
      <Navbar />
      <Outlet />
    </div>
  );
};

export default DesktopLayout;
