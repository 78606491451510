import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = ({parent = false}) => {
  const navigate = useNavigate();

  return (
    <div className={`${parent ? 'h-screen' : 'h-full w-full'} flex flex-col justify-center items-center bg-gray-100 text-gray-800`}>
      <h1 className="text-6xl font-bold mb-4">404</h1>
      <p className="text-xl mb-8">Oops! The page you're looking for doesn't exist.</p>
      <button
        onClick={() => navigate("/")}
        className="px-6 py-3 bg-blue-500 rounded-lg shadow hover:bg-blue-600 transition"
      >
        Go Back Home
      </button>
    </div>
  );
};

export default NotFound;
