import React from "react";
import MobileLayout from "./mobileLayout/MobileLayout";
import DesktopLayout from "./desktopLayout/DesktopLayout";
import useMediaQuery from "hooks/useMediaQuery";
import { useMatches } from "react-router-dom";

const PublicLayout = () => {
  const isMobile = useMediaQuery("(max-width: 640px)");
  const matches = useMatches();
  const currentRoute = matches[matches.length - 1]; // Get the current route
  const isDisabled = currentRoute.handle?.layoutProp;

  return (
      <div>
        {isMobile ? (
          <MobileLayout isDisabled={isDisabled} />
        ) : (
          <DesktopLayout />
        )}
      </div>
  );
};

export default PublicLayout;
