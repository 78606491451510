import React from 'react';

function GroupsIcon(props) {
  return (
    <svg
      width={23}
      height={17}
      viewBox="0 0 23 17"
      fill="none"
      {...props}
    >
      <path
        d="M11.5 7.727c-2.114 0-3.833-1.733-3.833-3.863S9.387 0 11.5 0c2.114 0 3.833 1.733 3.833 3.864 0 2.13-1.72 3.863-3.833 3.863zm0-6.182c-1.268 0-2.3 1.04-2.3 2.319a2.312 2.312 0 002.3 2.318c1.268 0 2.3-1.04 2.3-2.318a2.312 2.312 0 00-2.3-2.319zM17.633 17H5.367a.77.77 0 01-.767-.773v-4.636a2.312 2.312 0 012.3-2.318h9.2c1.268 0 2.3 1.04 2.3 2.318v4.636a.769.769 0 01-.767.773zm-11.5-1.546h10.734v-3.863a.77.77 0 00-.767-.773H6.9a.77.77 0 00-.767.773v3.864zM4.6 8.5c-1.691 0-3.067-1.386-3.067-3.09 0-1.706 1.376-3.092 3.067-3.092 1.691 0 3.067 1.386 3.067 3.091C7.667 7.114 6.29 8.5 4.6 8.5zm0-4.636c-.846 0-1.533.693-1.533 1.545S3.754 6.955 4.6 6.955c.846 0 1.533-.694 1.533-1.546 0-.852-.687-1.545-1.533-1.545zM3.067 16.227h-2.3A.77.77 0 010 15.454v-3.09a2.312 2.312 0 012.3-2.319h.843a.77.77 0 01.767.773.77.77 0 01-.767.773H2.3a.77.77 0 00-.767.773v2.318h1.534a.77.77 0 01.766.772.77.77 0 01-.766.773zM18.4 8.5c-1.691 0-3.067-1.386-3.067-3.09 0-1.706 1.376-3.092 3.067-3.092 1.691 0 3.067 1.386 3.067 3.091 0 1.705-1.376 3.091-3.067 3.091zm0-4.636c-.846 0-1.533.693-1.533 1.545s.687 1.546 1.533 1.546c.846 0 1.533-.694 1.533-1.546 0-.852-.687-1.545-1.533-1.545zm3.833 12.363h-2.3a.769.769 0 01-.766-.773c0-.427.342-.772.766-.772h1.534v-2.318a.77.77 0 00-.767-.773h-.843a.769.769 0 01-.767-.773c0-.427.343-.772.767-.772h.843c1.268 0 2.3 1.04 2.3 2.318v3.09a.769.769 0 01-.767.773z"
        fill={props.fill || '#fff'}
      />
    </svg>
  );
}

export default GroupsIcon;
