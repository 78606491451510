import React from 'react';

function ActivitiesIcon(props) {
  return (
    <svg
      width={33}
      height={32}
      viewBox="0 0 33 32"
      fill="none"
      {...props}
    >
      <path
        d="M.01 13.288a.631.631 0 01.393-.692l8.741-4.004c.167-.075.35-.118.547-.118h3.876c1.427 0 2.584 1.123 2.584 2.508v19.439a.622.622 0 01-.426.588.693.693 0 01-.221.039.64.64 0 01-.607-.414l-2.774-7.395-4.457 7.497a.649.649 0 01-.56.313c-.186-.021-.256-.046-.324-.084a.631.631 0 01-.323-.544l1.938-10.723v-7.211l-7.64 1.308a.651.651 0 01-.719-.403l-.028-.104zM9.69 3.459c0-1.384 1.157-2.508 2.584-2.508S14.86 2.075 14.86 3.46c0 1.385-1.157 2.509-2.584 2.509S9.691 4.844 9.691 3.459zm9.69 1.254c0-1.384 1.158-2.508 2.585-2.508 1.426 0 2.584 1.124 2.584 2.508 0 1.385-1.158 2.509-2.584 2.509-1.427 0-2.585-1.122-2.585-2.509zm11.952.399a.665.665 0 01.78.1.614.614 0 010 .888l-5.625 5.457V19.7l1.928 10.613a.623.623 0 01-.313.652c-.485.121-.75-.01-.884-.23l-4.457-7.494-2.774 7.395a.645.645 0 01-.828.375.628.628 0 01-.426-.588l.001-16.93c0-.928.521-1.739 1.292-2.172l11.306-6.21z"
        fill={props?.fill ?? '#434DE0'}
      />
    </svg>
  );
}

export default ActivitiesIcon;
