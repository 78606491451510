import { errorCatch } from './errorCatch.js'
import { PrivateApiCall, PublicApiCall } from './interceptors.js'

async function createLead(data) {
  return await PublicApiCall().post('/web/lead', data)
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

export {
  createLead
}

