import MobileModal from "components/mobileModal/MobileModal";
import QRCodeModal from "components/qrCodeModal/QRModal";
import PublicLayout from "layout/publicLayout/PublicLayout";
import ActivityPage from "pages/activityPage/ActivityPage";
import HomePage from "pages/homePage/HomePage";
import MerchantPage from "pages/merchantPage/MerchantPage";
import NotFound from "pages/notFound/NotFound";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

function App() {

  // useEffect(() => {
  //   const redirectToApp = () => {
  //     const appScheme = 'com.partof://'
  //     console.log(window)
  //     window.location = appScheme;
  //   }

  //   redirectToApp();
  // }, []);
  

  const router = createBrowserRouter([
    {
      path: "/",
      element: <PublicLayout />,
      errorElement: <NotFound parent={true} />,
      children: [
        {
          path: "",
          element: <HomePage />,
        },
        {
          path: "merchant/:id",
          element: <MerchantPage />,
          handle: { layoutProp: true },
        },
        {
          path: "activity/:id",
          element: <ActivityPage />
        },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
      <MobileModal />
      <QRCodeModal />
    </>
  );
}

export default App;
