import MobileCard from "components/mobileCard/MobileCard";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./MobileHomePage.css";
import { daysLeft } from "helpers/Helpers";
import { useContext } from "react";
import { PhoneModalContext } from "context/PhoneModalContext";
import Loading from "components/loading/Loading";
import { useOutletContext } from "react-router-dom";

const MobileHomePage = ({ Activities, loading }) => {
  const { setModalData, modalData } = useContext(PhoneModalContext);

  const { onBackgroundChange } = useOutletContext();

  const handleSlideChange = (swiper) => {
    const currentSlideIndex = swiper.realIndex || 0;
    const currentActivity = Activities[currentSlideIndex];
    setModalData({
      cardId: currentActivity?.id,
      color:
        currentActivity?.activitytype?.name === "event" ? "purple" : "orange",
    });
    onBackgroundChange(currentActivity?.activitytype?.name)
  };

  return (
    <div className="flex flex-col h-full items-center justify-center">
      {!loading ? (
        <>
        <Swiper
          spaceBetween={30}
          navigation={true}
          modules={[Navigation]}
          slidesPerView={1}
          loop
          className="w-full h-full"
          onSlideChange={handleSlideChange}
        >
          {Activities.length > 0 ? (
            Activities?.map((activity, index) => (
              <SwiperSlide key={index} className="h-full w-full px-4">
                <MobileCard
                  cardId={activity?.id}
                  title={activity?.title}
                  content={activity?.content}
                  description={activity?.description}
                  picture={activity?.images?.length > 0 && activity?.images[0]}
                  countdown={daysLeft(activity?.endDate)}
                  isEvent={activity?.activitytype?.name === "event"}
                />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide key={"0"} className="h-full w-full px-4">
              <MobileCard />
            </SwiperSlide>
          )}
        </Swiper>
        <style jsx>{`
        .swiper-button-prev,
        .swiper-button-next {
          color: ${modalData.color}; 
          font-size: 30px;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          border: 2px solid ${modalData.color};
        }
      `}</style>
      </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default MobileHomePage;
