import React from "react";
import Logo from "../../assets/logo.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="w-full flex items-center justify-between py-2">
      <Link to="/">
        <img src={Logo} alt="Logo" className="rounded-lg" />
      </Link>
    </nav>
  );
};

export default Navbar;
