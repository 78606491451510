import { useContext, useRef, useEffect } from "react";
import { QRCodeModalContext } from "context/QRModalContext";
import DefaultLogo from "../../assets/qrcode/qrcode.png"

const QRCodeModal = () => {
  const { showQrCodeModal, closeQrCodeModal } =
    useContext(QRCodeModalContext);

  const modalRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeQrCodeModal();
    }
  };

  useEffect(() => {
    // Perform any cleanup or reset operations if needed when the modal is opened/closed
  }, [showQrCodeModal]);

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 rounded-lg p-2 flex justify-center items-center z-50 ${
        showQrCodeModal ? "block" : "hidden"
      }`}
      onClick={handleOutsideClick}
    >
      <div
        className="bg-white max-w-md rounded-lg p-6 shadow-lg"
        ref={modalRef}
      >
        <div className="mb-4 text-lg">
          <p className={`block font-medium mb-4`}>
          Scannez le code QR ci-dessous:
          </p>
          <div className="flex justify-center items-center">
              <img
                src={DefaultLogo}
                alt="QR Code"
                className="w-64 h-64 object-contain"
              />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRCodeModal;
