import React from 'react';

function QRIcon(props) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_2486_67966)" fill={props.fill || "#390977"}>
        <path d="M1.8 2.7a.9.9 0 01.9-.9h1.8a.9.9 0 000-1.8H2.7A2.7 2.7 0 000 2.7v1.8a.9.9 0 001.8 0V2.7zM16.2 15.302a.9.9 0 01-.9.9h-1.8a.9.9 0 100 1.8h1.8a2.7 2.7 0 002.7-2.7v-1.8a.9.9 0 10-1.8 0v1.8zM1.8 15.302a.9.9 0 00.9.9h1.8a.9.9 0 110 1.8H2.7a2.7 2.7 0 01-2.7-2.7v-1.8a.9.9 0 111.8 0v1.8zM15.3 1.8a.9.9 0 01.9.9v1.8a.9.9 0 101.8 0V2.7A2.7 2.7 0 0015.3 0h-1.8a.9.9 0 100 1.8h1.8zM3.75 7.202a.9.9 0 00.9.9h2.7a.9.9 0 00.9-.9v-2.7a.9.9 0 00-.9-.9h-2.7a.9.9 0 00-.9.9v2.7zM3.75 13.498a.9.9 0 00.9.9h2.7a.9.9 0 00.9-.9v-2.7a.9.9 0 00-.9-.9h-2.7a.9.9 0 00-.9.9v2.7zM10.05 7.202a.9.9 0 00.9.9h2.7a.9.9 0 00.9-.9v-2.7a.9.9 0 00-.9-.9h-2.7a.9.9 0 00-.9.9v2.7zM10.05 13.498a.9.9 0 00.9.9h2.7a.9.9 0 00.9-.9v-2.7a.9.9 0 00-.9-.9h-2.7a.9.9 0 00-.9.9v2.7z" />
      </g>
      <defs>
        <clipPath id="clip0_2486_67966">
          <path fill={props.fill || "#fff"} d="M0 0H18V18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default QRIcon;
