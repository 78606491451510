import axios from "axios";

import { PARTOFAPI } from "./constants.js";

let PrivateApiCallConnection;

export function PrivateApiCall() {
  if (PrivateApiCallConnection) {
    return PrivateApiCallConnection;
  } else {
    PrivateApiCallConnection = axios.create({
      baseURL: PARTOFAPI,
    });
    // PrivateApiCallConnection = axios.create({
    //   baseURL: process.env.REACT_APP_BASE_URL,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   withCredentials: true, // Enables sending cookies or other credentials
    // });
    return PrivateApiCallConnection;
  }
}

PrivateApiCall().interceptors.request.use(
  (req) => {
    const token = localStorage.getItem("ptpAdminToken");
    req.headers.Authorization = `Bearer ${token}`;
    return req;
  },
  (err) => {
    throw err;
  }
);

PrivateApiCall().interceptors.response.use(
  (res) => res,
  (err) => {
    if (err?.reponse) {
      if (err?.response?.status === 401) {
        localStorage.clear();
        window.location.href = `${window.location.origin}/login`;
      }
    }
    throw err;
  }
);

let PublicApiCallConnection;

export function PublicApiCall() {
  if (PublicApiCallConnection) {
    return PublicApiCallConnection;
  } else {
    PublicApiCallConnection = axios.create({
      baseURL: PARTOFAPI,
    });
    // PublicApiCallConnection = axios.create({
    //   baseURL: process.env.REACT_APP_BASE_URL,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   withCredentials: true, // Enables sending cookies or other credentials
    // });
    return PublicApiCallConnection;
  }
}

PublicApiCall().interceptors.request.use(
  (req) => req,
  (err) => {
    throw err;
  }
);

PublicApiCall().interceptors.response.use(
  (res) => res,
  (err) => {
    throw err;
  }
);
