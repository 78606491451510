import "App.css";
import CustomCard from "components/card/CustomCard";
import CustomSearch from "components/search/CustomSearch";
import CustomSelect from "components/select/CustomSelect";
import { useEffect, useState } from "react";

function DesktopHomePage({ Activities }) {
  const options = [
    { value: "all", label: "ALL" },
    { value: "event", label: "ÉVÉNEMENT" },
    { value: "offer", label: "OFFRE" },
  ];

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("all");
  const [search, setSearch] = useState();
  const [filteredActivities, setFilteredActivities] = useState(Activities);
  const itemsPerPage = 10;

  useEffect(() => {
    const filterActivities = () => {
      let final = Activities;
      if (filter !== "all") {
        if (filter === "event") {
          final = Activities.filter((activity) => activity?.activitytype?.name === "event");
        } else if (filter === "offer") {
          final = Activities.filter((activity) => activity?.activitytype?.name === "offer");
        }
      }

      if (search) {
        final = final.filter(
          (activity) =>
            activity.title.toLowerCase().includes(search.toLowerCase())
           || activity.description.toLowerCase().includes(search.toLowerCase())
        );
      }

      setFilteredActivities(final);
      setCurrentPage(1);
    };

    filterActivities();
  }, [filter, search, Activities]);


  const totalPages = Math.ceil(filteredActivities.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = filteredActivities.slice(startIndex, startIndex + itemsPerPage);

  // Handle page change
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleFilter = (e) => {
    setFilter(e.target.value);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div>
      <h1 className="text-center font-semibold text-3xl text-purple pb-4 w-full">
        Bienvenue a Partof
      </h1>
      <div className="flex flex-col gap-2 w-full">
        <div className="flex flex-row justify-between items-center gap-1">
          <CustomSearch handleSearch={handleSearch} />
          <CustomSelect
            options={options}
            handleFilter={(e) => handleFilter(e)}
          />
        </div>
        <div className="grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-2 rounded-lg p-2 bg-white w-full shadow-lg h-[60vh] overflow-y-auto scrollable-container">
          {currentItems?.map((activity, index) => (
            <CustomCard
              key={index}
              cardId={activity?.id}
              isEvent={activity?.activitytype?.name === "event"}
              cardTitle={activity?.title}
              cardDescription={activity?.description}
              imageName={activity?.images?.length > 0 && activity?.images[0]}
              ProPicName={
                activity?.user?.images?.length > 0
                  ? activity?.user?.images[0]
                  : ""
              }
              userName={activity?.user?.title}
            />
          ))}
        </div>
        <div className="flex justify-center gap-2 flex-wrap mt-4">
          {/* Previous Button */}
          <button
            disabled={currentPage <= 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className={`px-3 py-2 rounded ${
              currentPage <= 1
                ? "text-halfTransparentBlack cursor-not-allowed"
                : "bg-white text-gray-700 hover:bg-gray"
            }`}
          >
            Prev
          </button>

          {/* Page Numbers */}
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`px-3 py-2 rounded ${
                currentPage === index + 1
                  ? "bg-gray font-bold"
                  : "bg-white text-black hover:bg-gray"
              }`}
            >
              {index + 1}
            </button>
          ))}

          {/* Next Button */}
          <button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            className={`px-3 py-2 rounded ${
              currentPage >= totalPages
                ? "text-halfTransparentBlack cursor-not-allowed"
                : "bg-white text-gray-700 hover:bg-gray-200"
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default DesktopHomePage;
