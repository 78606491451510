import { errorCatch } from "./errorCatch.js";
import { PublicApiCall } from "./interceptors.js";

async function getMerchantById(id) {
  return await PublicApiCall()
    .get(`/web/user/merchant/${id}`)
    .then((response) => response)
    .catch((error) => errorCatch(error));
}

export { getMerchantById };
