import EvenementsIcon from "assets/icons/EvenementsIcon";
import OffresIcon from "assets/icons/OffresIcon";
import { PhoneModalContext } from "context/PhoneModalContext";
import { truncateString } from "helpers/Helpers";
import { useContext } from "react";

const MobileCard = ({
  cardId,
  title,
  content,
  description,
  picture,
  countdown,
  isEvent,
}) => {
  const { openPhoneModal } = useContext(PhoneModalContext);

  const cardColor = isEvent ? "text-purple" : "text-orange";
  const buttonColor = isEvent ? "bg-purple" : "bg-orange";
  const color = isEvent ? "purple" : "orange";

  return (
    <div className={`gap-4 rounded-lg flex flex-col h-full w-full max-w-[650px] relative p-3 text-center ${cardColor} mx-auto my-auto`}>
      <div className={`bg-white rounded-full font-bold w-12 h-12 absolute top-0 right-0 bottom-2 border-2 flex items-center justify-center text-sm`}>
        {isEvent ?
          <EvenementsIcon fill={'#390977'} width={30} height={23} />
          : <OffresIcon fill={'rgba(245, 88, 0, 1)'} width={30} height={23} />}
      </div>
      <div
        className={`flex flex-col w-full h-full bg-white rounded-3xl gap-6 p-4`}
      >
        <span className={`font-semibold text-lg rounded-lg p-2 w-full`}>
          {countdown || "N/A"}
        </span>
        <img
          src={
            picture
              ? picture
              : "https://via.placeholder.com/150/cccccc/000000?text=No+Image"
          }
          alt={title}
          className="max-w-80 max-h-80 w-full h-full rounded-lg self-center"
        />
        {/* Content Section */}
        <div className="flex flex-col gap-2">
          <h3 className={`text-2xl font-bold`}>{title}</h3>
          <p className="text-gray-500 mb-2 text-base">
            {truncateString(content, 25)}
          </p>
          <p className={`text-gray-500 mb-2 text-base `}>
            {truncateString(description, 120)}
          </p>
        </div>

        {/* Footer Section */}
        <div className="flex flex-row justify-between gap-4 w-full mt-auto">
          <button
            className={`p-2 rounded-lg text-white ${buttonColor} text-nowrap w-full`}
            onClick={() => openPhoneModal(cardId, color)}
          >
            Je veux
          </button>
          <button
            className={`p-2 shadow-lg rounded-lg border-${color} border w-full `}
            onClick={() => openPhoneModal(cardId, color)}
          >
            Voir la boutique
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileCard;
