import BottomBar from "components/bottombar/BottomBar";
import { Link, Outlet } from "react-router-dom";
import Logo from "../../../assets/logo.png";
import { useState } from "react";
import OFFERIMG from '../../../assets/backgrounds/OrangePark.png'
import EVENTIMG from '../../../assets/backgrounds/PurplePark.png'

const MobileLayout = ({ isDisabled }) => {
  const [bgImage, setBgImage] = useState(OFFERIMG);
  const [type, setType] = useState()

  const handleChangeBackground = (activityType) => {
    setType(activityType)
    const newBgImage = activityType === "event"
      ? EVENTIMG
      : OFFERIMG;

    setBgImage(newBgImage); 
  };

  return (
    <div
      className="max-h-screen h-screen flex flex-col max-w-screen py-2"
      style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
      <div className="px-4">
        <Link to="/">
          <img src={Logo} alt="Logo" className="rounded-lg cursor-pointer" />
        </Link>
      </div>
      <div className="flex flex-col h-full w-full">
        <Outlet context={{ onBackgroundChange: handleChangeBackground }} />
      </div>
      <BottomBar isDisabled={isDisabled} type={type} />
    </div>
  );
};

export default MobileLayout;
