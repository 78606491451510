import { createContext, useState } from "react";

const PhoneModalContext = createContext();

const PhoneModalContextProvider = ({ children }) => {
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [modalData, setModalData] = useState({ cardId: null, color: null });

  const openPhoneModal = (cardId, color) => {
    setModalData({cardId, color});
    setShowMobileModal(true);
  };

  const closePhoneModal = () => {
    setShowMobileModal(false);
  };

  return (
    <PhoneModalContext.Provider
      value={{
        showMobileModal,
        setShowMobileModal,
        modalData,
        setModalData,
        openPhoneModal,
        closePhoneModal,
      }}
    >
      {children}
    </PhoneModalContext.Provider>
  );
};

export { PhoneModalContext, PhoneModalContextProvider };
